const sConflictData = "Konflik data";
const sPayloadBadRequestID = "Permintaan payload tidak valid";
const sInternalErrorID = "Kesalahan internal";

const beforeDateError = "Mohon maaf, harap isi tanggal sebelum hari ini";
const notInOpenPeriod = "Tidak dalam periode terbuka";
const sDisabledAccount = "Akun dinonaktifkan";
const sAccountValidationRulesFail = "Aturan validasi akun gagal";
const systemError = "Mohon maaf, ada kesalahan sistem. Silakan hubungi tim GajiGesa";
const sPocketType = "Tipe Kantong";
const sEmpDataProcess = "Proses Data Karyawan";
const sEmpStatusChange = "Perubahan Status Karyawan";
const sEmployeeId = "ID Karyawan";
const sEmployeeName = "Nama Karyawan";
const salaryDisbursement = "Salary Disbursement";
const sEmployeeData = "Data Karyawan";
const sStatus = "Status Verifikasi";
const sSaveAndSend = "Simpan & Kirim";
const attentionText = "Perhatian!";
const okayText = "Saya Mengerti";
const initialOnboarding = "Onboarding Karyawan";
const manageTeam = "Kelola Data Karyawan";
const groupAndPayDate = "Grup dan Tanggal Cut Off";
const transactionHistory = "Transaksi";
const invoice = "Invoice";
const timAkses = "Tim Akses";
const id = {
  common: {
    language: "Ubah bahasa",
  },
  departments: {
    hr: "Departemen Sumber Daya Manusia",
    finance: "Departemen Keuangan",
    ops: "Departemen Operasional",
    am: "Departemen Manajemen Klien",
    others: "Lainnya",
  },
  errorCodes: {
    documentIncorrect:
      "Upload Gagal! Ada beberapa data di dokumen Anda yang salah atau kurang. Silahkan periksa kembali dan upload ulang",
    "GG-BENE-01": sConflictData,
    "GG-BENE-02": sPayloadBadRequestID,
    "GG-BENE-03": "Sudah ada",
    "GG-BENE-04": "Jumlah penerima yang terdaftar sudah mencapai batas",
    "GG-BENE-05": "Penghapusan tidak diizinkan",
    "GG-CON-OTP-01": "Token OTP diperlukan",
    "GG-CON-OTP-02": "Token OTP tidak valid",
    "GG-CON-OTP-03": "Token OTP sudah diverifikasi",
    "GG-CON-OTP-04": "Silahkan coba lagi atau kirim ulang kode",
    "GG-CON-OTP-05": "Token OTP tidak ditemukan",
    "GG-CON-OTP-06": "Kode verifikasi tidak valid. Silahkan coba lagi atau kirim ulang kode",
    "GG-CON-OTP-07": "Terlalu banyak permintaan kode verifikasi. Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-CON-OTP-08": "Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-EMP-01": "Pegawai tidak ditemukan",
    "GG-EMP-02": "Status pegawai tidak aktif",
    "GG-EMP-03": "Pegawai ditangguhkan",
    "GG-EMP-04": "Pemberi kerja pegawai ditangguhkan",
    "GG-EMP-05": "Transaksi pegawai terkunci",
    "GG-EMP-06": "Pemberi kerja pegawai tidak aktif",
    "GG-EMPYER-01": "Pemberi kerja tidak ditemukan",
    "GG-EMPYER-02": "Status pemberi kerja tidak aktif",
    "GG-EMPYER-03": "Pemberi kerja ditangguhkan",
    "GG-EMPYER-04": "Gagal menyimpan pemberi kerja",
    "GG-EMPYER-05": sConflictData,
    "GG-EMPYER-08": "Akun EWA Perusahaan ini sudah dinonaktifkan permanen",
    "GG-EMPYER-LOGIN-01": "Email tidak valid",
    "GG-EMPYER-LOGIN-02": "Email atau kata sandi salah",
    "GG-EMPYER-LOGIN-03": "Pemberi kerja ditangguhkan",
    "GG-EMPYER-LOGIN-04": "Status pemberi kerja tidak aktif",
    "GG-GEN-01": "Permintaan tidak valid",
    "GG-GEN-02": "Terlalu banyak permintaan",
    "GG-GEN-03": "API tidak ditemukan",
    "GG-GEN-04": sInternalErrorID,
    "GG-GEN-05": "JWT tidak sah",
    "GG-GEN-06": "AppKey tidak sah",
    "GG-GEN-07": "Fitur tidak diotorisasi",
    "GG-GEN-08": "Waktu respons habis",
    "GG-GEN-09": sConflictData,
    "GG-GEN-10": "Kesalahan parameter batas",
    "GG-GEN-11": "Kesalahan parameter lewati",
    "GG-GEN-12": "Parameter batas tidak valid",
    "GG-GEN-13": "Parameter lewati tidak valid",
    "GG-GEN-14": "Parameter nomor halaman tidak valid", // TODO: change this. used Google Translate
    "GG-GEN-15": "Batas wajib diisi", // TODO: change this. used Google Translate
    "GG-GEN-16": "pageNo wajib diisi", // TODO: change this. used Google Translate
    "GG-GEN-17": "Tokennya sudah habis masa berlakunya", // TODO: change this. used Google Translate
    "GG-GEN-18": "Record not found",
    "GG-GEN-20": "Hanya file .xlsx diperbolehkan",
    "GG-GOLD-01": "Data permintaan tidak valid",
    "GG-GOLD-02": "Profil sudah ada",
    "GG-GOLD-03": "Profil Treasury tidak ditemukan",
    "GG-OTP-01": "Nomor telepon tidak valid",
    "GG-OTP-02": "Nomor telepon diblokir",
    "GG-OTP-03": "AppKey tidak valid",
    "GG-OTP-04": "Mengirim ulang habis",
    "GG-OTP-05": "Kesalahan layanan SMS OTP",
    "GG-OTP-06": "Jenis pengiriman pesan tidak valid",
    "GG-OTP-07": "Nomor telepon belum terdaftar",
    "GG-OTP-08": "Terlalu banyak permintaan kode verifikasi. Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-PHONE-01": sPayloadBadRequestID,
    "GG-PHONE-02": "Sudah ada",
    "GG-PHONE-03": "Jumlah nomor telepon yang terdaftar sudah mencapai batas",
    "GG-PHONE-04": "Penghapusan tidak diizinkan",
    "GG-PRODUCT-01": sInternalErrorID,
    "GG-PRODUCT-02": "Produk tidak ditemukan",
    "GG-PRODUCT-03": "Provider diatur oleh fitur bendera",
    "GG-PRODUCT-04": "Produk tidak tersedia",
    "GG-PRODUCT-05": "ID tipe akun yang disediakan tidak valid",
    "GG-PRODUCT-06": "Muatan permintaan buruk",
    "GG-SIGNUP-01": "Data sudah ada",
    "GG-SIGNUP-02": sInternalErrorID,
    "GG-SIGNUP-03": sPayloadBadRequestID,
    "GG-SIGNUP-04": "Nama Pemberi Kerja tidak valid",
    "GG-SIGNUP-05": "Nama Pengguna tidak valid",
    "GG-SIGNUP-06": "Payload Nomor Telepon tidak valid",
    "GG-SIGNUP-07": "Jenis Identitas tidak valid",
    "GG-SIGNUP-08": "ID Identitas tidak valid",
    "GG-TRX-01": "Pengguna dalam periode kunci",
    "GG-TRX-02": "Transaksi pegawai ditangguhkan",
    "GG-TRX-03": "Kategori pegawai diblokir",
    "GG-TRX-04": "Akun diblokir untuk kategori",
    "GG-TRX-05": "Saldo tidak mencukupi",
    "GG-TRX-06": sInternalErrorID,
    "GG-TRX-07": "Kesalahan provider",
    "GG-TRX-08": "Produk tidak ditemukan",
    "GG-TRX-09": "Produk tidak tersedia",
    "GG-TRX-10": "Kategori Transaksi Tidak Valid", // TODO: change this. Used google translate
    "GG-TRX-11": "Info bank tidak ditemukan", // TODO: change this. Used google translate
    "GG-TRX-13": "Bank Terdaftar tidak disetujui", // TODO: change this. Used google translate
    "GG-TRX-14": "Jumlah yang diminta kurang dari minimum penarikan",
    "GG-USER-01": "Pengguna tidak ditemukan",
    "GG-USER-02": "Status pengguna tidak aktif",
    "GG-USER-03": "Pengguna ditangguhkan",
    "GG-USER-04": "Gagal mengambil info pengguna",
    "GG-USER-05": "KTP Pengguna tidak ditemukan",
    "GG-VAL-01":
      "Status Karyawan salah. Status yang diisi wajib aktif, tidak aktif, penarikan ditangguhkan, dan penarikan akrual ditangguhkan ",
    "GG-VAL-02": "Format jumlah minimal salah. Format wajib diiisi dalam angka",
    "GG-VAL-03": "Format jumlah maksimal salah. Format wajib diisi dalam angka",
    "GG-VAL-04": "Format tanggal gajian salah. Format wajib diisi dalam teks",
    "GG-VAL-05": "Tipe akun wajib EWA, Poin, Emas, Penalangan Gaji, Uang Kantor, Uang Kas, dan Penalangan Gaji Offline",
    "GG-VAL-06": "Format grup karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-07": "Format pencarian salah. Format wajib diisi dalam teks",
    "GG-VAL-08": "Format kode karyawan salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-09": "Format grup karyawan sekunder salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-10": "Format identitas karyawan salah. Format wajib diisi dalam angka",
    "GG-VAL-11": "Format kategori penangguhan salah. Format wajib diisi dalam teks",
    "GG-VAL-12": "Format alasan penangguhan salah. Format wajib diisi dalam teks",
    "GG-VAL-13": "Format email salah",
    "GG-VAL-14": "Format dari tanggal transaksi salah. Format wajib diisi dalam teks tanggal",
    "GG-VAL-15": "Format ke tanggal transaksi salah. Format wajib diisi dalam teks tanggal",
    "GG-VAL-16": "Nilai untuk status transaksi salah. Status transaksi wajib MENUNGGU, SUKSES, DITOLAK, GAGAL",
    "GG-VAL-17": "Format identitas kategori transaksi salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-18": "Format dari jumlah transaksi salah. Format wajib diisi dalam angka",
    "GG-VAL-19": "Format ke jumlah transaksi salah. Format wajib diisi dalam angka",
    "GG-VAL-20": "Format identitas rekan salah. Format wajib diisi dalam angka",
    "GG-VAL-21": "Format kode karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-22":
      "Format status pengajuan karyawan salah. Status pengajuan wajib menunggu, sukses, dalam proses, gagal, atau ditolak",
    "GG-VAL-23": 'Tipe kerah wajib "BIRU" atau "PUTIH"',
    "GG-VAL-24": "Format identitas pengguna salah. Format wajib diisi dalam angka",
    "GG-VAL-25": "Format nama depan salah. Format wajib diisi dalam teks",
    "GG-VAL-26": "Format nama belakang salah. Format wajib diisi dalam teks",
    "GG-VAL-27": "Format jenis pengajuan salah. Format wajib diisi dalam teks",
    "GG-VAL-28": "Format tanggal lahir salah. Format wajib diisi dalam teks",
    "GG-VAL-29": "Format nomor handphone salah. Format wajib diisi dalam teks",
    "GG-VAL-30": "Format jenis kelamin salah. Format wajib diisi dalam teks",
    "GG-VAL-31": "Format nomor KTP salah. Format wajib diisi dalam teks",
    "GG-VAL-32": "Format tanggal bergabung salah. Format wajib diisi dalam teks",
    "GG-VAL-33": "Format nama grup salah. Format wajib diisi dalam teks",
    "GG-VAL-34": "Format nama departemen salah. Format wajib diisi dalam teks",
    "GG-VAL-35": "Format nama penunjukkan salah. Format wajib diisi dalam teks",
    "GG-VAL-36":
      "Format identitas tipe gaji salah. Jenis gaji wajib BULANAN, HARIAN, MINGGUAN, UNIT_KERJA, PER JAM, DUA MINGGU SEKALI, BULANAN_TETAP, JENJANG WAKTU_TETAP, DUA BULAN_SEKALI",
    "GG-VAL-37": "Format jumlah gaji salah. Format wajib diisi dalam angka",
    "GG-VAL-38": "Format nama bank karyawan salah. Format wajib diisi dalam angka",
    "GG-VAL-39": "Format nomor akun bank karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-40": "Format nama penerima salah. Format wajib diisi dalam teks",
    "GG-VAL-41": "Format nama file salah. Format wajib diisi dalam teks",
    "GG-VAL-42": "Format nama karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-43":
      "Format permintaan karyawan diurutkan berdasarkan salah. Urutan wajib berdasarkan kode karyawan, nama karyawan, atau diupload pada",
    "GG-VAL-44": "Format urutan permintaan karyawan salah. Pengurutan harus menaik atau menurun",
    "GG-VAL-47": "Nilai untuk gender tidak valid. Jenis kelamin harus PRIA atau WANITA.", // TODO: change this. Used Google Translate
    "GG-VAL-49": "Nama Bank tidak sesuai dengan nomor rekening",
    "GG-VAL-50": "Nomor rekening tidak terdaftar. Silahkan konfirmasi ke karyawan Anda",
    "GG-VAL-51": "Nama pemilik rekening salah. Silahkan periksa kembali",
    "GG-VAL-52": "Nomor Handphone sudah terdaftar.",
    "GG-VAL-53": "ID Karyawan sudah terdaftar. Pastikan tidak menggunakan ID yang sudah terdaftar.",
    "GG-VAL-54": "Kategori Penangguhan diperlukan saat status tidak aktif",
    "GG-VAL-55": "Alasan Penangguhan diperlukan saat Kategori Penangguhan adalah Eksternal Lainnya",
    "GG-VAL-56": "Nomor Handphone karyawan tidak boleh kosong",
    "GG-VAL-57": "Jenis Kelamin karyawan tidak boleh kosong",
    "GG-VAL-58": "Nomor KTP tidak boleh kosong",
    "GG-VAL-59": "ID Karyawan tidak boleh kosong",
    "GG-VAL-60": "Tanggal Karyawan Bergabung di Perusahaan tidak boleh kosong",
    "GG-VAL-61": "Nama Bank tidak boleh kosong",
    "GG-VAL-62": "Nomor Rekening tidak boleh kosong",
    "GG-VAL-63": "Perubahan Status Karyawan tidak valid. Alasan harus dikosongkan jika Status Karyawan adalah Aktif",
    "GG-VAL-64": "Perubahan status karyawan tidak valid. Detail alasan harus dikosongkan jika Alasan bukan Lainnya",
    "GG-VAL-65":
      "Perubahan status karyawan tidak valid. Detail Alasan harus dikosongkan jika Status Karyawan adalah Aktif",

    "GG-VAL-66": "Nama Pemilik Rekening tidak boleh kosong dan tidak lebih dari 255 karakter",
    "GG-VAL-67": beforeDateError,
    "GG-VAL-68": beforeDateError,
    "GG-VAL-69": "Nama Karyawan tidak lebih dari 50 karakter",
    "GG-VAL-70": "ID Karyawan tidak lebih dari 25 karakter",
    "GG-VAL-71": "Tidak boleh ada spasi pada ID Karyawan",
    "GG-VAL-101": "Tanggal Lahir Karyawan tidak boleh kosong",
    "GG-EMP-REQ-01": "Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa",
    "GG-EMP-REQ-02":
      "Gagal upload karena ada dokumen yang masih diverifikasi. Silahkan coba lagi setelah dokumen disetujui!",
    "GG-EMP-REQ-03": "Tidak ada perubahan data",
    "GG-EMP-REQ-04": "Perubahan status karyawan gagal. Silakan pilih dan masukan detail alasan terlebih dahulu!",
    "GG-EMP-REQ-05": "Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa",
    "GG-EMP-REQ-06": "Gagal upload karena error sistem. Silahkan coba lagi",
    "GG-EMP-REQ-07": "ID Karyawan tidak ditemukan",
    "GG-EMP-REQ-08": "Karyawan tidak ditemukan",
    "GG-EMP-REQ-09": "Nomor HP tidak valid",
    "GG-EMP-REQ-10":
      "Permintaan untuk ID karyawan atau nomor handphone berikut sedang dalam verifikasi. Mohon untuk tidak menambahkan data lain sebelum data selesai kami proses.",
    "GG-EMP-REQ-11":
      "Anda tidak bisa merubah status karyawan menjadi aktif karena akun EWA Perusahaan Anda sedang dinonaktifkan sementara. Hubungi tim GajiGesa untuk informasi selanjutnya.",
    "GG-EMP-REQ-12":
      "Anda tidak bisa menambah karyawan karena akun EWA Perusahaan Anda sedang dinonaktifkan sementara. Hubungi tim GajiGesa untuk informasi selanjutnya.",
    "GG-KTP-01": "Nomor KTP tidak valid",
    "GG-KTP-02": "Nama KTP tidak valid",
    "GG-KTP-03": "Panjang nomor KTP melebihi batas",
    "GG-KTP-04": "Panjang nama KTP melebihi batas",
    "GG-KTP-05": "Panjang angka KTP kurang dari panjang yang dibutuhkan",
    "GG-RESEND-OTP-01": "Token OTP diperlukan", // TODO: change this. Used Google translate
    // "GG-RESEND-OTP-02": "Otp token not found", // TODO: change this. Same error code twice. Tagged Adrian for this.
    "GG-RESEND-OTP-02": "Kirim ulang token OTP", // TODO: change this. Used Google translate
    "GG-REASON-TYPE-01": "Jenis Alasan Filter Invalid",
    "GG-EXT-01": "Kesalahan API sisi mitra",
    "GG-PARTNER-01": "diperlukan majikan",
    "GG-PARTNER-02": "majikan harus menjadi array",
    "GG-PARTNER-03": "GroupName harus menjadi array",
    "GG-PARTNER-04": "SecondaryGroupName harus menjadi array",
    "GG-PARTNER-05": "Dibeletlah harus boolean",
    "GG-PARTNER-06": "Halaman harus menjadi angka",
    "GG-PARTNER-07": "Nomor halaman tidak valid",
    "GG-PARTNER-08": "batas harus menjadi angka",
    "GG-PARTNER-09": "Batasi tidak valid",
    "GG-INVOICE-01": "Tidak ada catatan yang ditemukan untuk generasi RN",
    "GG-INVOICE-02": "Nama Grup wajib diisi", // TODO: change this. Used google translate
    "GG-PC-01": "Siklus pembayaran tidak ditemukan",
    "GG-TRXH-01": "Status bukan array",
    "GG-TRXH-02": "ID siklus pembayaran bukanlah string",
    "GG-REQ-WD-01": "Akun tidak valid",
    "GG-REQ-WD-02": "Alasan tidak valid",
    "GG-REQ-WD-03": "Keseimbangan tidak cukup",
    "GG-REQ-WD-04": notInOpenPeriod,
    "GG-REQ-WD-05": sDisabledAccount,
    "GG-REQ-WD-06": sAccountValidationRulesFail,
    "GG-REQ-WD-07": "Nomor rekening bank tidak terdaftar / disetujui",
    "GG-REQ-WD-08": "Nomor rekening bank tidak valid",
    "GG-REQ-WD-09": "Jumlah permintaan yang tidak valid",
    "GG-REQ-WD-10": "ID Alasan Tidak Valid",
    "GG-REQ-WD-11": "validation",
    "GG-REQ-WD-12": "Kode rujukan tidak valid",
    "GG-CON-WD-01": "Transaksi tidak ditemukan",
    "GG-CON-WD-02": "Akun tidak valid",
    "GG-CON-WD-03": "Keseimbangan tidak cukup",
    "GG-CON-WD-04": notInOpenPeriod,
    "GG-CON-WD-05": sDisabledAccount,
    "GG-CON-WD-06": sAccountValidationRulesFail,
    "GG-RF-01": "Kode rujukan tidak valid",
    "GG-RF-02": "Kode rujukan tidak berlaku. Karena ini bukan tnx pertama",
    "GG-POINT-01": "Akun poin tidak ada",
    "GG-SF-01": "Profil gaji belum terdaftar",
    "GG-LOAN-01": "Kode produk untuk pinjaman tidak valid", // TODO:  change this. Used Google translate
    "GG-LOAN-02": "Karyawan tidak memenuhi syarat untuk produk pinjaman", // TODO:  change this. Used Google translate
    "GG-LOAN-03": "Kode pinjaman harus berupa string", // TODO:  change this. Used Google translate
    "GG-LOAN-04": "Kode pinjaman wajib diisi", // TODO:  change this. Used Google translate
    "GG-LOAN-05": "Pengajuan pinjaman sudah ada", // TODO:  change this. Used Google translate
    "GG-LOAN-06": "Id aplikasi tidak valid", // TODO:  change this. Used Google translate
    "GG-LOAN-07": "ID Aplikasi harus berupa nomor yang valid dan bukan nol", // TODO:  change this. Used Google translate
    "GG-CON-BP-01": "ID Penyedia tidak ditemukan", // TODO: change this. Used Google translate
    "GG-CON-BP-02": "Permintaan telah kedaluwarsa. Silakan bertanya lagi", // TODO: change this. Used Google translate
    "GG-REQ-BP-01": "akun tidak ditemukan", // TODO: change this. Used Google translate
    "GG-REQ-BP-02": "Alasan tidak valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-03": "Saldo tidak cukup", // TODO: change this. Used Google translate
    "GG-REQ-BP-04": notInOpenPeriod, // TODO: change this. Used Google translate
    "GG-REQ-BP-05": sDisabledAccount, // TODO: change this. Used Google translate
    "GG-REQ-BP-06": sAccountValidationRulesFail, // TODO: change this. Used Google translate
    "GG-REQ-BP-09": "Jumlah Permintaan Tidak Valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-10": "Id Alasan Tidak Valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-11": "nomor rekening tidak valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-12": "kode referral tidak valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-13": "Deskripsi alasan tidak valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-14": "Jumlah pascabayar dari ayoconnect tidak valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-15": "Maaf, sistem sedang mengalami masalah. Silakan coba lagi sebentar lagi", // TODO: change this. Used Google translate
    "GG-REQ-BP-16": "Penagihan belum tersedia", // TODO: change this. Used Google translate
    "GG-REQ-BP-17": "Nomor HP/ ID Pelanggan salah. Silahkan cek kembali", // TODO: change this. Used Google translate
    "GG-REQ-BP-18": "Nomor ini bukan pascabayar", // TODO: change this. Used Google translate
    "GG-REQ-BP-19": "Kategori BP Transaksi Tidak Valid", // TODO: change this. Used Google translate
    "GG-REQ-BP-20": "Transaksi gagal, silakan coba lagi", // TODO: change this. Used Google translate
    "GG-REQ-BP-21": "Voucher telah habis masa berlakunya. Nantikan pembaruan rutin!", // TODO: change this. Used Google translate
    "GG-REQ-BP-22": "Permintaan Transaksi sudah dikonfirmasi", // TODO: change this. Used Google translate
    "GG-SD-REQ-01": "Aksi ini tidak diperbolehkan",
    "GG-SD-REQ-02":
      "Upload Gagal! Ada beberapa data di dokumen Anda yang salah atau kurang. Silakan sesuaikan dengan template yang tersedia dan upload ulang",
    "GG-SD-REQ-03":
      "Upload Gagal! File tidak boleh lebih dari 1000 baris/row. Silakan periksa kembali dan upload ulang",
    "GG-SD-REQ-04": "Tanggal penjadwalan Salary Disbursement yang diharapkan wajib diisi",
    "GG-SD-REQ-05": "Penjadwalan hanya bisa dilakukan minimal 1 hari setelah proses upload data ini",
    "GG-SD-REQ-06": "Pengajuan Salary Disbursement tidak sah",
    "GG-SD-REQ-07": "Headers/ Judul dalam file tidak sesuai dengan template yang disediakan",
    "GG-SD-REQ-08": systemError,
    "GG-SD-REQ-09": "Pengajuan tidak ditemukan",
    "GG-SD-REQ-10": "Format ID tidak valid",
    "GG-SD-REQ-11": systemError,
    "GG-SD-REQ-12": systemError,
    "GG-SD-REQ-13": systemError,
    "GG-GRP-PAY-01": "Grup harus kosong. Silakan hubungi tim GajiGesa untuk pembuatan grup.",
    "GG-GRP-PAY-02": "Grup tidak boleh kosong. Silakan pilih Grup dari opsi yang tersedia di Grup dan Tanggal Cut Off.",
    "GG-GRP-PAY-03": "Grup tidak valid. Silakan pilih Grup dari opsi yang tersedia di Grup dan Tanggal Cut Off.",
    "GG-GRP-PAY-04":
      "Tanggal Cut off tidak boleh kosong. Silakan pilih Tanggal dari opsi yang tersedia di Grup dan Tanggal Cut Off.",
    "GG-GRP-PAY-05":
      "Tanggal Cut off tidak valid. Silakan pilih Tanggal dari opsi yang tersedia di Grup dan Tanggal Cut Off.",
    "GG-EMP-PIC-01": "PIC sudah ada untuk id pengguna yang diberikan",
    "GG-EMP-PIC-02": "Nama PIC tidak valid. Harus dalam format string",
    "GG-EMP-PIC-03": "Nama PIC diperlukan.",
    "GG-EMP-PIC-04": "Nomor Telepon PIC tidak valid. Harus dalam format Nomor Telepon Indonesia",
    "GG-EMP-PIC-05": "Nomor Telepon PIC diperlukan.",
    "GG-EMP-PIC-06": "Nama Departemen PIC tidak valid. Harus dalam format string",
    "GG-EMP-PIC-07": "Nama Departemen PIC diperlukan.",
    "GG-EMP-PIC-08": "Jenis Akses PIC tidak valid.",
    "GG-EMP-PIC-09": "Jenis Akses PIC diperlukan.",
    "GG-EMP-PIC-10": "Pengguna PIC tidak ditemukan.",
    "GG-EMP-PIC-11": "Pengguna sudah ada untuk nomor telepon yang diberikan.",
    "GG-EMP-PIC-12": "Pengguna ada untuk nomor ponsel yang diberikan.",
    "GG-EMP-INV-01": "Filter akun tidak valid. Harus antara 0 hingga 6",
    "GG-EMP-INV-02": 'Filter status tidak valid. Harus "PAID", "DUE", "OVERDUE"',
    "GG-EMP-INV-03": "Filter Tanggal Mulai tidak valid",
    "GG-EMP-INV-04": "Filter Tanggal Berakhir tidak valid",
    "GG-EMP-INV-05": "Filter Tanggal Jatuh Tempo tidak valid",
    basicValidation: {
      lettersOnly: "Harap gunakan huruf saja",
      tooLong: "Terlalu panjang",
      tooShort: "Terlalu pendek",
      numbersOnly: "Harap gunakan angka saja",
      noMinus: "Angka tidak bisa minus",
      noZeros: "Tidak bisa menggunakan 0",
      invalidPhone: "Nomor handphone tidak valid",
      phoneExists: "Nomor sudah terdaftar",
      missingFields: "Mohon maaf, harap di isi",
      futureDate: beforeDateError,
      properFormat: "Format nomor HP salah",
    },
  },
  home: {
    welcome: "Dashboard Karyawan GajiGesa",
    hello: "Hai",
    summary: {
      ringkasan: "Ringkasan Tim",
      tipe: sPocketType,
      totalEmployees: "Total Karyawan",
      employee: "Karyawan",
      active: "Status Aktif",
      inactive: "Status Non Aktif",
      ewa: "Akun Gaji",
      uangKantor: "Uang Kantor",
      salaryDisbursement: "Penalangan Gaji",
    },
    progressBox: {
      title: sEmpDataProcess,
      description: "Cek status penambahan dan perubahan data karyawan disini",
      button: "Cek Data",
    },
    statusCheckBox: {
      title: sEmpStatusChange,
      description: "Cek status perubahan “Status Karyawan” disini",
      button: "Cek Data",
    },
    list: {
      title: "Lihat Tim",
      header: {
        no: "No",
        employeeId: sEmployeeId,
        employeeName: sEmployeeName,
        walletType: sPocketType,
        payDate: "Tanggal Cutoff",
        withdrawLimit: "Limit Penarikan",
        group: "Grup",
        status: "Status",
        reason: "Alasan",
        walletToolTip: "Kantong dana yang dimiliki oleh karyawan Anda",
        payDateToolTip: "Tanggal Terakhir Perhitungan Gaji Karyawan.",
        withdrawToolTip: "Batas gaji yang bisa ditarik oleh karyawan melalui EWA GajiGesa",
        salaryAmt: "Nominal Gaji",
        ktpNumber: "Nomor Handphone",
        phoneNumber: "Nomor KTP",
        bankAccountNumber: "Nomor Rekening",
      },
      rows: {
        active: "Aktif",
        inactive: "Non Aktif",
        endMonth: "Akhir Bulan",
        MALE: "Laki-laki",
        FEMALE: "Perempuan",
        BLUE: "Kerah Biru",
        WHITE: "Kerah Putih",
        withdraw_suspended: "Non Aktif",
        Aktif: "Aktif",
        "Non Aktif": "Non Aktif",
        withdraw_accrual_suspended: "Non Aktif",
      },
    },
    filter: {
      search: "Cari",
      searchPlaceholder: "Masukkan Nama atau ID Karyawan",
      filter: "Filter",
      download: "Unduh Data",
      add: "Kelola Karyawan",
    },
    export: {
      success: "Email baru tersimpan. Data akan terkirim ke email kamu kurang lebih dalam 5 Menit",
    },
    statusChange: {
      success: "Status atau Alasan berhasil diubah",
    },
    statusReason: {
      "API/ System Errors": "Kesalahan API/Sistem",
      "Business Closure": "Penutupan Bisnis",
      "Employee Request": "Permintaan Karyawan",
      "End Contract with Employer": "Akhiri Kontrak dengan Majikan",
      "End Contract with GG": "Akhiri Kontrak dengan GG",
      "GG Action": "Aksi GG",
      "Hit Employer Limit": "Capai Batas Pemberi Kerja",
      "HR Issues": "Masalah SDM",
      "Late Payment": "Pembayaran Terlambat",
      "Moved Employer within GG": "Pindah Majikan dalam GG",
      "Moved to Competitor": "Dipindahkan ke Pesaing",
      "Other Internal": "Internal lainnya",
      "Permanent Deactivation": "Penonaktifan Permanen",
      "Proactive Deactivation": "Penonaktifan Proaktif",
      "SDO/ST Only": "Hanya untuk Penggajian (SD)",
      Temporary: "Sementara",
      "Test/ Dummy Account": "Akun Tes/Dummy",
      "Close Cycle": "Close Cycle",
      "Data Review": "Peninjauan Data",
      Dismissal: "Pemutusan Hubungan Kerja",
      "Duplicate/ Incorrect Data": "Data Duplikasi/Salah",
      "Employer Action": "Lainnya",
      "Other External": "Lainnya",
      Fraud: "Penipuan",
      Resignation: "Mengundurkan Diri",
      "Waiting Go-Live": "Menunggu Aktivasi",
    },
  },
  login: {
    welcome: "Selamat Datang!",
    warning: "Akses dari desktop untuk pengalaman yang lebih baik dalam menggunakan dashboard ini",
    phone: "Nomor HP",
    otp: "Kode verifikasi terkirim ke nomor ",
    phonePlaceholder: "Masukkan nomor HP dengan format 628xxxxx",
    error: "Nomor telpon invalid",
    otpError: "OTP invalid",
    whatsapp: "Kirim OTP Lewat Whatsapp",
    sms: "Kirim OTP Lewat SMS",
    signIn: "Masuk",
    noReceive: "Tidak menerima kode verifikasi?",
    resend: "Kirim ulang",
    or: "atau",
    changePhone: "Ganti nomor HP",
    copyright: "© 2023 GajiGesa. All rights reserved.",
    otpResent: "OTP telah dikirim. Coba lagi",
  },
  basicValidation: {
    lettersOnly: "Harap gunakan huruf saja",
    tooLong: "Terlalu panjang",
    tooShort: "Terlalu pendek",
    numbersOnly: "Harap gunakan angka saja",
    noMinus: "Angka tidak bisa minus",
    noZeros: "Tidak bisa menggunakan 0",
    invalidPhone: "Nomor handphone tidak valid",
    phoneExists: "Nomor sudah terdaftar",
    missingFields: "Mohon maaf, harap di isi",
    futureDate: beforeDateError,
    properFormat: "Format nomor HP salah",
    youCanSelectOnlyFutureDates:
      "Mohon maaf, Anda hanya bisa memilih tanggal untuk besok atau tanggal-tanggal setelahnya",
    pleaseSelectGroupFirst: "Mohon Maaf, harap pilih grup terlebih dahulu",
    noSpaces: "Tidak boleh ada spasi pada ID Karyawan",
  },
  roles: {
    EMPLOYEE: manageTeam,
    "GROUP-PAYDATE": groupAndPayDate,
    TRANSACTION: transactionHistory,
    SD: salaryDisbursement,
    INVOICE: invoice,
    "TEAM-ACCESS": timAkses,
  },
  sideBar: {
    initialOnboarding,
    manageTeam,
    groupAndPayDate,
    transactionHistory,
    invoice,
    timAkses,
    whatsappSupport: "Whatsapp Support",
    logOut: "Logout",
    singleCreate: "Tambah Satu Karyawan",
    inProgress: sEmpDataProcess,
    inStatusCheck: sEmpStatusChange,
    changeEmployeeData: "Ubah Data Karyawan",
    bulkCreate: "Tambah Beberapa Karyawan",
    bulkUpdate: "Ubah Data Beberapa Karyawan",
    bulkUpdateStatus: "Ubah Beberapa Status Karyawan",
    salaryDisbursement,
    sdScheduling: "Penjadwalan Salary Disbursement",
    sdScheduledData: "Data Untuk Penjadwalan",
    iobBulkCreate: "Upload Data",
    addPIC: "Ubah data",
  },
  employeeCreation: {
    single: {
      personalData: "Data Pribadi",
      personalDataDesc: "Data Pribadi adalah informasi pribadi karyawan Anda.",
      employeeData: sEmployeeData,
      employeeDataDesc:
        "Data Karyawan adalah informasi detail tentang karyawan Anda meliputi informasi jenis karyawan, pembayaran gaji dan jadwal kerja.",
      info: "Perhatian",
      infoDesc:
        "Tanggal absen terakhir karyawan yang disepakati oleh perusahaan dan GajiGesa. Pastikan mengisi dengan benar.",
      fullName: "Nama Lengkap (Wajib)",
      phone: "Nomor Handphone (Wajib / Format wajib dimulai dengan 628)",
      gender: "Jenis Kelamin (Wajib)",
      birthDate: "Tanggal Lahir",
      ktp: "Nomor KTP (Wajib)",
      employeeId: "ID Karyawan (Wajib)",
      employeeType: "Tipe Karyawan (Wajib)",
      bankName: "Nama Bank (Wajib)",
      beneficiaryName: "Nama Pemilik Rekening (Wajib)",
      salaryType: "Tipe Gaji (Wajib)",
      payDate: "Tanggal Terakhir Perhitungan Gaji Karyawan (Wajib)",
      dateJoined: "Tanggal Bergabung Perusahaan (Wajib)",
      group: "Grup",
      bankNumber: "Nomor Rekening (Wajib)",
      status: "Status Karyawan (Wajib)",
      salary: "Nominal Gaji (Wajib)",
      endOfMonth: "Akhir Bulanan",
      selectDate: "Pilih Tanggal Gajian",
      submit: "Simpan",
      cancel: "Batalkan",
      success: "Karyawan berhasil disimpan",
      pending: "Data karyawan baru sedang menunggu verifikasi",
      error: "Perubahan data karyawan ditolak",
      reason: "Alasan (Wajib)",
      chooseDetailedReason: "Jika Pilih Lainnya Tulis Detail Alasan (Wajib)",
      suspensionCategoryPlaceholder: "Pilih Alasan",
      suspendedReasonPlaceholder: "Masukkan Detail Alasan",
      selectGroup: "Pilih Grup",
      selectGroupPlaceholder: "Cari Grup",
      noGroup: "Tidak Ada Grup",
      maxWithdrawal: "Limit Penarikan (Akan diisi oleh tim GajiGesa)",
      salType: "Bulanan",
    },
    bulk: {
      addSeveralEmployees: "Tambah Beberapa Karyawan",
      addSeveralDesc: "Anda bisa mendaftarkan beberapa karyawan secara sekaligus dengan mudah",
      followTheseSteps: "Ikuti langkah-langkah berikut ini :",
      selectDocumentTemplate:
        "Sebelum meng-upload dokumen, pastikan Anda sudah menggunakan template yang kami sediakan",
      afterDownloadingFillDetailsAndExport:
        "Setelah mendownload template, pastikan anda mengisi data sesuai dengan template dan mengeksport nya dengan tipe “.XLSX” sebelum menguploadnya dengan maksimal ukuran 25 MB",
      pleaseUploadFileInFormat: "Silahkan upload dokumen Anda. File yang dapat di-upload adalah dalam bentuk ”.XLSX”",
      downloadTemplate: "Download Template",
      changeDocument: "Ganti Dokumen",
      or: "atau",
      wipe: "Hapus",
      clickHereToUpload: "Klik disini untuk mengupload",
      orDragFilesToThisArea: "atau seret file ke area ini",
      maxFileSize25mb: "Ukuran file maksimum 25 MB.",
      maxFileSizeExceeded: "File yang Anda upload melebih ukuran file maksimum. Silahkan periksa dan coba lagi",
      incorrectFileFormat: "Format file yang Anda upload salah. Silahkan periksa dan coba lagi",
      cancel: "Kembali",
      submit: "Lanjutkan",
      uploadingPleaseWait: "Sedang meng-upload, mohon tunggu…",
    },
    BLUE: "Kerah Biru",
    WHITE: "Kerah Putih",
  },
  employeeUpdation: {
    single: {
      update: "Perubahan data karyawan disetujui",
      pending: "Perubahan pada data karyawan sedang menunggu keputusan",
      rejected: "Perubahan data karyawan ditolak",
      failed: "Perubahan pada data karyawan gagal",
      processing: "Perubahan data karyawan sedang menunggu verifikasi",
      warningTitle: "Dalam Verifikasi",
      WarningDescription:
        "Beberapa data sedang dalam proses verifikasi tim GajiGesa. Kami akan memberitahu kamu jika verifikasi sudah selesai",
      dormantTitle: attentionText,
      dormantText:
        "Karyawan ini sudah tidak aktif lebih dari 30 hari. Jika Anda ingin mengaktfikan kembali mohon pastikan semua datanya sudah yang paling baru/ terkini",
    },
    bulk: {
      changingEmployeeDataAtOnce: "Pengubahan Data Karyawan Sekaligus",
      bulkUpdateDesc: "Anda bisa mengubah data banyak karyawan secara sekaligus",
    },
    bulkUpdateStatus: {
      title: "Ubah Beberapa Status Karyawan",
      desc: "Anda bisa sekaligus mengubah banyak status karyawan dari “Aktif ke Non Aktif” dan sebaliknya",
    },
  },
  inProgress: {
    title: sEmpDataProcess,
    waiting: "Beberapa data karyawan baru sedang menunggu diverifikasi",
    downloadDataAndCheckForErrors: "Download data untuk cek error",
    table: {
      header: {
        title: sEmployeeData,
        no: "No",
        rowExcel: "Row Excel",
        employeeId: sEmployeeId,
        employeeName: sEmployeeName,
        uploadDate: "Diupload Pada",
        fileName: "Nama File",
        status: sStatus,
        note: "Catatan",
        actionType: "Tipe Aksi",
        employeeStatus: "Status Karyawan",
        reason: "Alasan",
      },
    },
    processingFileToast: "Upload data sedang dalam proses",
    fileNameCopy: "Nama file berhasil disalin.",
    searchPlaceholder: "Cari Nama atau ID Karyawan",
  },
  timAkses: {
    tableHeaderTitle: "List Anggota Tim",
    headerTitle: "Tim Akses",
    listHeader: "List Anggota Tim",
    tableHeader: {
      name: "Nama Karyawan",
      phone: "Nomor Handphone",
      roles: "Jabatan",
      actions: "Hapus Akses",
    },
    addUser: {
      btnTitle: "Tambah Anggota",
      namePlaceHolder: "Masukkan Nama Karyawan",
      createSuccessToast: "Anggota berhasil ditambahkan!",
      editSuccessToast: "Perubahan Berhasil!",
    },
    departments: "Jabatan",
    rolesField: "Pilih Hak Akses",
  },
  inStatusCheck: {
    title: sEmpStatusChange,
    table: {
      header: {
        title: sEmployeeData,
        no: "No",
        employeeId: sEmployeeId,
        employeeName: sEmployeeName,
        uploadDate: "Diupload Pada",
        fileName: "Nama File",
        status: sStatus,
        note: "Catatan",
      },
    },
  },
  toolTip: {
    totalEmployees: "Jumlah seluruh karyawan yang terdaftar di GajiGesa",
    totalActiveEmployees: "Karyawan yang bisa melakukan transaksi di GajiGesa",
    totalSuspendEmployees: "Karyawan yang tidak bisa melakukan transaksi di GajiGesa",
    ewa: "Akun EWA karyawan",
    uangKantor: "Akun yang digunakan sebagai dana operasional kantor",
    salaryDisbursement: "Akun gaji karyawan yang diterima melalui penalangan GajiGesa",
    walletType: "Kantong dana yang dimiliki oleh karyawan Anda",
    payDate: "Tanggal Terakhir Perhitungan Gaji Karyawan.",
    withdrawalLimit: "Batas gaji yang bisa ditarik oleh karyawan melalui EWA GajiGesa",
    documentIsBeingUploaded: "dokumen sedang diupload…",
    uploadFailedDueToSystemError: "Unggahan gagal karena kesalahan sistem. Silakan coba lagi.",
    documentUploadedSuccessfully: "dokumen berhasil diupload",
    suspensionCategory: `Untuk me-nonaktifkan secara permanen ganti alasan :\n Pemutusan Hubungan Kerja dan Mengundurkan Diri`,
  },
  modal: {
    export: {
      title: "Masukkan email untuk download",
      description: "File yang Anda download akan dikirim ke email terdaftar Anda kurang lebih 5 menit",
      placeholder: "contoh : agni@alamatemail.com",
      button: sSaveAndSend,
      buttonLoad: "Mohon Tunggu…",
    },
    create: {
      confirm: "Konfirmasi Tambah Karyawan",
      confirmDesc: "Pastikan semua data sudah benar sebelum menyimpan",
      submit: "Simpan",
      cancel: "Batalkan",
    },
    edit: {
      title:
        "Pastikan semua data sudah benar. Jika kamu mengubah nomor handphone, segera informasikan ke karyawan terkait untuk masuk ke GajiGesa menggunakan nomor handphone yang baru",
    },
    delete: {
      cancel: "Batalkan",
      confirm: "Hapus",
      title: "Hapus Akses",
      description: "Kamu yakin ingin menghapus akses untuk ",
    },
    update: {
      confirm: "Simpan Perubahan?",
      confirmDesc:
        "Apakah Anda ingin menonaktifkan karyawan ini? \nCatatan: Karyawan yang telah dinonaktifkan hanya dapat diaktifkan kembali melalui Tim GajiGesa.",
      confirmDescSusCategory: "Apakah Anda ingin merubah “Alasan Status Non Aktif?”",
      submit: "Ya, Simpan",
      cancel: "Batalkan",
    },
    cancel: {
      title: "Tinggalkan Halaman?",
      desc: "Apakah Anda yakin ingin meninggalkan halaman ini? Data yang telah Anda masukkan akan hilang.",
      stay: "Tetap Disini",
      leave: "Tinggalkan",
    },
    login: {
      title: "Kirim Ulang Kode Verifikasi",
      description: "Silahkan pilih metode kirim ulang kode verifikasi Anda",
    },
    bulk: {
      confirm: "Konfirmasi Tambah Beberapa Karyawan",
      confirmDesc: "Pastikan dokumen yang diupload sudah benar.",
      leavePage: "Tinggalkan Halaman",
      areYouSureYouWantToLeave:
        "Apakah Anda yakin ingin meninggalkan halaman ini? Data yang telah Anda masukkan akan hilang.",
      leave: "Tinggalkan",
      stayHere: "Tetap Disini",
      iobConfirm: "Konfirmasi Onboarding Karyawan",
      summaryBox: "Proses Data Onboarding Karyawan",
    },
    sd: {
      confirm: "Konfirmasi Penjadwalan",
      confirmDesc: "Data yang diupload akan kami cek terlebih dahulu",
      submit: "Lanjutkan",
      cancel: "Batalkan",
      submitSDScheduleDesc: "Kami akan memproses penjadwalan.",
      submitScheduleWarning: "Pastikan data sudah tepat, data tidak dapat diubah setelah dijadwalkan.",
      wipeSDSchedule: "Hapus Data?",
      wipeSDScheduleDesc: "Apakah anda yakin ingin menghapus  data yang siap dijadwalkan?",
      dontDelete: "Jangan Hapus",
      yesDelete: "Ya Hapus",
    },
    suspension: {
      status: "Status:",
      active: "Aktif",
      inactive: "Non-Aktif",
      reasonForInactivity: "Alasan Non-Aktif (Wajib Diisi):",
      writeDetailedReason: "Jika Pilih Lainnya Tulis Detail Alasan: (Wajib Diisi)",
      cancel: "Batalkan",
      save: "Simpan",
      placeholder: "Masukkan Detail Alasan",
    },
  },
  filter: {
    title: "Pilih Bedasarkan",
    walletType: sPocketType,
    status: "Status",
    group: "Grup",
    payDate: "Tanggal Cut Off",
    withdrawLimit: "Limit Penarikan",
    reset: "Reset",
    save: "Simpan",
    actionType: "Tipe Aksi",
    fileName: "Nama File",
    withdrawalAmount: "Jumlah Penarikan",
    startingFrom: "Mulai Dari (Rp)",
    upTo: "Sampai Dengan (Rp)",
    transactionStatus: "Status Penarikan",
    purposeOfWithdrawal: "Tujuan Penarikan",
    searchHere: "Cari Disini",
    noData: "Tidak ada data",
  },
  transactionPage: {
    table: {
      no: "No",
      withdrawalDate: "Tanggal Penarikan",
      employeeId: sEmployeeId,
      employeeName: sEmployeeName,
      group: "Grup",
      withdrawalAmount: "Jumlah Penarikan",
      purposeOfWithdrawal: "Tujuan Penarikan",
      status: "Status",
    },
    transaction: "Transaksi",
    downloadDataSuccess: "Download Data Sukses ke Email Id",
    notes: "Catatan",
    dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle:
      "Data yang tertera bersumber pada data penarikan gaji dalam siklus penggajian periode ini atau yang Anda pilih",
    transactionDate: "Tanggal transaksi",
    dataSummary: "Ringkasan Data",
    totalSalaryWithdrawals: "Total Penarikan Gaji",
    numberOfTransactions: "Jumlah Transaksi",
    totalEmployeesTransacting: "Total Karyawan yang Bertransaksi",
    viewTransactions: "Lihat Transaksi",
    enterEmployeeNameOrID: "Masukkan Nama atau ID Karyawan",
    search: "Cari",
    downloadData: "Download Data",
    downloadAllData: "Download Semua Data",
    downloadDataWithFilters: "Download Berdasarkan Filter",
    downloadThisMonthsData: "Download Data Bulan Ini",
    REJECTED: "Ditolak",
    PENDING: "Menunggu",
    COMPLETED: "Sukses",
    FAILED: "Gagal",
    CANCELLED: "Dibatalkan",
    NOT_STARTED: "Belum Mulai",
    SUCCESS: "Sukses",
    all: "Semua",
  },
  employeeForm: {
    withdrawalText: "Limit Penarikan (Akan diisi oleh tim GajiGesa)",
    withdrawalNotify: "Hubungi Tim GajiGesa jika ingin merubah Limit Penarikan",
    statusEmployerWS:
      "Tidak dapat mengubah status karyawan ke aktif karena akun EWA perusahaan Anda sedang dinonaktifkan sementara",
  },
  groupPaydate: {
    columnOne: "No",
    columnTwo: "Nama Grup",
    columnThree: "Tanggal Cut Off",
    title: "Grup & Tanggal Cut Off",
    cutOffTitle: "Grup & Tanggal Cut Off",
    noticeTitle: "Catatan Penting",
    noticeOne:
      "Cek Grup dan Tanggal Cut Off saat anda akan melakukan penambahan atau perubahan beberapa data karyawan.",
    noticeTwo: "Jika Perusahaan/ karyawan Anda ada yang tidak memiliki grup, silahkan pilih",
    noticeFive: "“Tidak Ada Grup”",
    noticeFour: "saat melakukan penambahan atau perubahan beberapa data karyawan",
    noticeThree: "Hubungi tim GajiGesa jika Anda ingin menambah atau merubah Grup",
    tableText: "Daftar Grup & Tanggal Cut Off",
  },
  salaryDisbursement: {
    title: salaryDisbursement,
    noData: "Penjadwalan Salary Disbursement akan ditampilkan disini",
    scheduling: {
      title: salaryDisbursement,
      description: "Mulai penjadwalan Salary Disbursement karyawan di sini",
    },
    SDScheduleBox: {
      title: "Penjadwalan Salary Disbursement",
      description: "Mulai penjadwalan salary disbursement karyawan di sini",
      button: "Jadwalkan",
    },
    SDRequestBox: {
      title: "Data Untuk Penjadwalan",
      description: "Cek data yang siap untuk dijadwalkan/ditolak di sini",
      button: "Cek Data",
      warningBeforeSendingSdRequest:
        "Sebelum melakukan penjadwalan, pastikan data karyawan yang dipilih sudah tepat. Data karyawan tidak bisa diubah setelah data dijadwalkan!",
      submitScheduling: "Kirim Penjadwalan",
      wipe: "Hapus",
      totalNominal: "Total Nominal",
      selectedData: "Data Terpilih",
      downloadTitle: "Download Template",
      downloadDesc: "File yang Anda unduh akan dikirim ke email Anda yang terdaftar kurang dalam “5 Menit”.",
      downloadPlaceholder: "agni@gajigesa.com",
      downloadButton: sSaveAndSend,
      checkRejectedDataByDownloadingData:
        "Cek data yang ditolak dengan mendowload data. Jika kamu keluar dari halaman ini data ditolak akan langsung dihapus",
    },
    SDDetailsBox: {
      title: "Penjadwalan Penalangan",
      breadcrumb: "Nomor Penjadwalan",
      placeholder: "Masukkan Nomor HP, Nama Karyawan atau Nomor Rekening",
    },
    list: {
      title: "Daftar Penjadwalan",
      header: {
        no: "No",
        schedulingNumber: "Nomor Penjadwalan",
        uploadDate: "Tanggal Upload",
        totalNominal: "Total Nominal",
        verificationStatus: sStatus,
        rejectionReason: "Alasan Ditolak",
        disbursementStatus: "Status Pencairan",
        scheduledOn: "Dijadwalkan Pada",
        download: "Download",
        employeeId: sEmployeeId,
        employeeName: sEmployeeName,
        grup: "Grup",
        mobilePhoneNumber: "Nomor HP",
        BankName: "Nama Bank",
        accountNumber: "Nomor Rekening",
        nameOfAccountOwner: "Nama Pemilik Rekening",
        transferNews: "Berita Transfer",
        companyEmail: "Email Perusahaan",
        notes: "Catatan",
      },
    },
    steps: {
      makeSureSelectTheTemplate:
        "Sebelum meng-upload dokumen, pastikan Anda sudah menggunakan template yang kami sediakan",
      selectExpectedSchedulingDate:
        "Pilih tanggal penjadwalan yang diharapkan: Penjadwalan hanya bisa dilakukan minimal 1 hari setelah proses upload data ini",
      ensureTemplateDateAndFileType:
        "Sebelum di-upload, pastikan data yang Anda isi sudah sesuai dengan template dan eksport keseluruhannya dalam tipe “.XLSX”",
      pleaseUploadYourDocument: "Silahkan upload dokumen Anda. File yang dapat di-upload adalah dalam bentuk ”.XLSX”",
    },
    table: {
      header: {
        title: "Daftar Data Penjadwalan",
      },
      verifiedTab: "Data Dapat Diproses",
      rejectedTab: "Data Ditolak",
    },
    tooltip: {
      submitScheduleSuccess: "Semua data sudah tepat dan siap dijadwalkan",
      documentIncorrect:
        "Upload Gagal! Ada beberapa data di dokumen Anda yang salah atau kurang. Silahkan periksa kembali dan upload ulang",
      dataIsDeleted: "Data sudah dihapus!",
      pleaseSelectEmployees: "Silahkan pilih karyawan terlebih dahulu untuk penjadwalan Salary Disbursementnya",
      pleaseSelectEmployeesToDelete: "Silahkan pilih karyawan terlebih dahulu untuk dihapus dari Salary Disbursement",
    },
    modal: {
      title: "Konfirmasi pengiriman data",
      description: "File yang Anda unduh akan dikirim ke email Anda yang terdaftar kurang dalam “5 Menit”.",
      placeholder: "agni@gajigesa.com",
      button: sSaveAndSend,
    },
  },
  errorFallBack: {
    title: "Ups, ada kesalahan!",
    desc: "Tenang, sedang kami cek untuk kamu. Silakan tutup dulu dan buka kembali halaman ini",
    refresh: "Refresh Halaman",
    sideBarTitle: "Tidak dapat menampilkan menu!",
    sideBarDesc: "Silakan refresh atau tutup dulu dan buka kembali halaman ini",
    checkYourInternetConnection: "Coba cek kembali koneksi internetmu dan refresh halaman",
  },
  status: {
    completed: "Disetujui",
    pending: "Menunggu",
    rejected: "Ditolak",
    failed: "Gagal",
  },
  banner: {
    wsBannerText:
      "Akun EWA Perusahaan Anda sedang dinonaktifkan sementara, hubungi tim GajiGesa untuk informasi selanjutnya. Saat ini Anda hanya bisa melakukan Penambahan Data Karyawan, Perubahan Data Karyawan dan Perubahan Alasan Penonaktifan",
  },
  modalAlerts: {
    ws: {
      title: attentionText,
      content:
        "Akun EWA Perusahaan Anda sedang dinonaktifkan sementara, hubungi tim \nGajiGesa untuk informasi selanjutnya. \nSaat ini Anda hanya bisa melakukan  \n 1. Penambahan Data Karyawan \n 2. Perubahan Data Karyawan \n 3. Perubahan Alasan Penonaktfian",
      btnText: okayText,
    },
    dormant: {
      title: attentionText,
      content1: `Karyawan ini sudah tidak aktif lebih dari`,
      content2: ` 30 hari`,
      content3: `. Jika Anda ingin mengaktfikan kembali mohon pastikan semua datanya sudah yang paling baru/ terkini`,
      btnText: okayText,
    },
    iob: {
      title: attentionText,
      content:
        "Halo rekan HR, selamat datang di Employer Dashboard GajiGesa. Sebelum memulai, mohon upload data karyawan terlebih dahulu, sesuai data yang sudah di approved di KYB process.",
      btnText: okayText,
    },
    iob_congo: {
      title: "Selamat!",
      content:
        "Proses Onboarding Karyawan Anda sudah selesai! Sekarang Anda bisa mengakses semua fitur - fitur HR Dashboard",
      btnText: "Oke",
    },
    noAccess: {
      title: "Perhatian!",
      content: "Kamu tidak memiliki akses ke halaman ini. Silahkan kontak tim GajiGesa atau HR perusahaan kamu",
      btnText: "Mengerti",
    },
  },
  iobProcessData: {
    bulkBannerTitle: "Upload Data",
    bulkBannerDesc: "Mulai proses Onboarding Karyawan dengan mengupload data yang diperlukan disini",
    bulkUploadBtn: "Mulai",
  },
  invoices: {
    pageTitle: "Invoices",
    table: {
      listHeader: "List Invoice",
      raiseDispute: "Kontak",
      view: "Lihat",
      viewAndPay: "Bayar & Lihat",
      header: {
        group: "Grup",
        deliveryDate: "Tanggal\nPengiriman",
        startDate: "Tanggal Mulai",
        dateEnd: "Tanggal\nBerakhir",
        dueDate: "Jatuh Tempo",
        totalInvoiceValue: "Total Nilai Invoice",
        totalJumlah: "Total",
        totalFee: "Total Fee",
        totalTransaction: "Total Transaksi",
        status: "Status",
        actions: "Aksi",
        invoiceNumber: "Nomor Invoice",
      },
    },
    dateEndFilter: "Tanggal Berakhir",
    disputeModalTitle: "Masalah di invoice?",
    disputeModalDesc: "Untuk keluhan/masalah apa pun pada faktur kami, silakan tulis email ke:",
    disputeModalCancel: "Batalkan",
    disputeModalSubmit: "Kirim Email",
    payConfirmation: "Konfirmasi",
    payModalDesc:
      "Kamu akan diarahkan ke halaman pembayaran dimana kamu bisa cek detail invoice dan melakukan pembayaran",
    payModalCancel: "Kembali",
    payModalSubmit: "Lanjutkan",
  },
};

export default id;
