import { HStack, Text } from "native-base";
import { IoWarningOutline, IoRefresh } from "react-icons/io5";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { logOfflineEvent } from "./logOfflineEvent";

const ShowOnlineBanner = ({ refreshPage = () => {} }) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    logOfflineEvent();
  }, []);

  return (
    <HStack
      bgColor={colors.error.softRose}
      textAlign="center"
      alignItems="center"
      justifyContent="space-between"
      py="8px"
      px="16px"
      style={{
        position: "absolute",
        width: "100%",
        zIndex: 100,
      }}
    >
      <HStack>
        <IoWarningOutline size="24px" color={colors.error.rose} />
        <Text ml="8px">{t("errorFallBack.checkYourInternetConnection")}</Text>
      </HStack>
      <IoRefresh
        size="24px"
        color={colors.neutral.charcoal}
        onClick={refreshPage}
      />
    </HStack>
  );
};

export default ShowOnlineBanner;
