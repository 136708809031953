import { Box, Button, Center, Image, Modal, Text } from "native-base";
import { isEmptyObject } from "pages/SingleCreate/utils";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStore } from "store/storeUtils";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

export function shouldShowContent(modalPayload, t) {
  return modalPayload?.contents?.map((e) => {
    if (e.isBold)
      return (
        <Text variant="xs-bold" bold>
          {t(e.text)}
        </Text>
      );
    return <Text variant="xs">{t(e.text)}</Text>;
  });
}

const ModalAlerts = () => {
  const { modalPayload, setModalAlert, meData } = getStore();
  const { t } = useTranslation("common");
  const [showModalTemp, setShowModalTemp] = useState(false);

  const showModal = useMemo(() => {
    return !isEmptyObject(modalPayload);
  }, [modalPayload]);

  useEffect(() => {
    setShowModalTemp(showModal ?? false); // to create sequential delay
  }, [showModal]);

  function handleaDismissalOrCancel(type) {
    // add the amplitude based on dismiss or cancel
    AmplitudeHelper.logEvent("modal_button_clicked", {
      user_Id: meData?.userId,
      source: modalPayload?.source,
      employer_id: meData?.id,
      type: modalPayload?.amplitudeKey,
      icon: type,
    });
    setModalAlert({}); // clsore fo the modal
  }

  useEffect(() => {
    if (showModalTemp) {
      AmplitudeHelper.logEvent("modal_appear", {
        user_Id: meData?.userId,
        source: modalPayload?.source,
        employer_id: meData?.id,
        type: modalPayload?.amplitudeKey,
        employeeId: modalPayload?.employeeId,
      });
    }
  }, [showModalTemp]);

  if (!showModal) return null;

  return (
    <Center>
      <Modal isOpen={showModalTemp} onClose={() => handleaDismissalOrCancel("x")}>
        <Modal.Content backgroundColor={colors.neutral.cotton} maxWidth="402px">
          <Modal.CloseButton />
          <Modal.Header backgroundColor={colors.neutral.cotton}>{t(modalPayload.titleText)}</Modal.Header>
          <Modal.Body backgroundColor={colors.neutral.cotton}>
            <Center>
              <Image src={modalPayload.imageSource} style={{ width: "328px", height: "172px" }} />
            </Center>
            <Box marginX="32px">
              <Text variant="xs">{shouldShowContent(modalPayload, t)}</Text>
            </Box>
          </Modal.Body>
          <Modal.Footer justifyContent="center" backgroundColor={colors.neutral.cotton}>
            <Button
              display="flex"
              marginX="16px"
              flex={1}
              variant="solid"
              onPress={() => handleaDismissalOrCancel("saya_mengerti")}
            >
              {t(modalPayload.buttonText)}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default ModalAlerts;
