import { Center, Spinner } from "native-base";
import colors from "theme/colors";

const AppSpinner = () => {
  return (
    <Center justifyContent="center" alignItems="center" h="700px">
      <Spinner size="lg" color={colors.secondary.orchid} />
    </Center>
  );
};

export default AppSpinner;
