import { Text, Button } from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import { showComponents } from "pages/SingleCreate/utils";
import { getIfSDA } from "utils/utilities";
import { getStore } from "store/storeUtils";

const RouteButton = ({
  route = "",
  currentRoute = "",
  setCurrentRoute = () => {},
  isFileUploading = false,
  showModalForDisableUpload = () => {},
}) => {
  const isCurrentRoute = currentRoute?.id == route?.id;
  const { t } = useTranslation("common");

  const { employerConfigData } = getStore();

  const isSda = getIfSDA(employerConfigData);
  if (!employerConfigData) return null;

  if (!isSda && route?.isSDA) return null;
  if (showComponents(employerConfigData, route))
    return (
      <Button
        variant="rounded"
        bg={isCurrentRoute ? colors.neutral.cotton : colors.secondary.orchid}
        borderWidth={1}
        borderColor={colors.neutral.cotton}
        my="7px"
        onPress={() => {
          if (!isFileUploading) {
            AmplitudeHelper.logEvent(route?.amplitudeEvent);
            setCurrentRoute(route);
          } else {
            showModalForDisableUpload();
          }
        }}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Text
          variant={isCurrentRoute ? "xs-bold" : "xs"}
          color={isCurrentRoute ? colors.neutral.charcoal : colors.neutral.cotton}
          alignItems="center"
        >
          {route?.icon}
          {"  "}
          {t(route?.name)}
        </Text>
      </Button>
    );
};

export default RouteButton;
